/***************************************************************************************************
 * COMPONENTS AND THEMES
 */

/*
 * Angular Material, material design components
 * see https://material.angular.io

 */

@import 'libs/shared/ui/styles/src/lib/scss/m3-theme';

/***************************************************************************************************
   * UTILITIES
   */

/*
   * TailwindCSS, utility-first css framework
   * see https://tailwindcss.com/docs
   */
@tailwind base;
@tailwind components;
@tailwind utilities;

/***************************************************************************************************
   * OVERRIDES
   */

@layer base {
  :root {
    --mat-stepper-line-color: var(--outline-variant-color);
    --mat-stepper-header-done-state-icon-background-color: var(
      --primary-color-hex
    );
    --mat-stepper-header-done-state-icon-foreground-color: var(
      --on-primary-color
    );
  }
}

button {
  outline: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  @apply m-0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

mat-dialog-container {
  &.mat-dialog-container {
    @apply rounded-none;
  }
}
/*
  weird border issue Angular material with Tailwind https://github.com/tailwindlabs/tailwindcss/discussions/9993
  in preflight style of tailwinds sets border-style to solid, other way to fix it, would be to adjust
  preflight, which can case other issues for tailwind
  */
.mdc-notched-outline__notch {
  border-right: none !important;
}

mat-horizontal-stepper.primary-stepper
  mat-step-header[aria-selected='false']
  .mat-step-label {
  @apply hidden md:block;
}

mat-horizontal-stepper.primary-stepper
  mat-step-header[aria-labelledby='disabled'] {
  @apply pointer-events-none cursor-not-allowed;
}

schaeffler-html-report .mat-expansion-panel-content h2 {
  @apply text-title-large sm:text-headline-small;
}

// devices with top notch iOS and newer Android devices
.mat-drawer-inner-container {
  padding-top: env(safe-area-inset-top);
}

.schaeffler-header-logo {
  margin-right: env(safe-area-inset-right);
}
